import XR3 from './icons/xr3.png';
import Kusama from './icons/kusama.png';
import Litmus from './icons/litmus.png';
import GameP from './icons/game.png';
import Moon from './icons/moon.png';

const Icons = {
    XR3,
    Kusama,
    Litmus,
    GameP,
    Moon
}

export default Icons;